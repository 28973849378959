import { IonButton, IonIcon, IonLabel, useIonRouter } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  getProductTypes,
  getSingleProduct,
  createProduct,
  updateProduct,
} from '../../../context/actions/products';
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  GET_SINGLE_PRODUCT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
} from '../../../context/actions/types';
import useGlobalContext from '../../../context/provider';
import { DeviceContext } from '../../../deviceType';
import { useFileUpload } from '../../../hooks/files';
import { FolderEnum } from '../../../utils/fileUpload';
import classes from './styles.module.scss';
import Layout from '../../../components/Layout/dashboard';
import Spinner from '../../../components/spinner';
import { ProductType } from '../../../types/product';
import ErrorToast from '../../../components/error_toast';
import { getCurrentStore } from '../../../utils/currentStore';
import { getStore } from '../../../context/actions/store';
import { arrowBackSharp, closeSharp, saveSharp } from 'ionicons/icons';
import classNames from 'classnames';
import InputCard from '../../../components/inputField';
import RichTextInput from '../../../components/rich text input';
import SelectField from '../../../components/selectField';
import ImageCarouselInput from '../../../components/imageCarouselInput';

const CreateEditProduct = () => {
  const size = useContext(DeviceContext);
  const router = useIonRouter();
  const [product_types, setproduct_types] = useState<string[]>([]);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    productTypes,
    productTypesDispatch,
    productState,
    productDispatch,
    storeDispatch,
  } = useGlobalContext();
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [item, setItem] = useState<
    Omit<ProductType, 'price'> & { price: string }
  >({} as Omit<ProductType, 'price'> & { price: string });
  const { uploadFile } = useFileUpload();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const storeId = getCurrentStore();

  useEffect(() => {
    setLoading(true);
    async function fetchProductTypes() {
      if (productTypes.productTypes) {
        return setproduct_types(productTypes.productTypes.data);
      }
      await getProductTypes()(productTypesDispatch);
    }
    fetchProductTypes();
  }, []);

  useEffect(() => {
    if (item.id) {
      if (location.pathname.includes('edit')) {
        setLoading(false);
      }
    }
  }, [item]);

  useEffect(() => {
    if (product_types.length) {
      const path = location.pathname;
      if (path.includes('edit-product') && !item.id) {
        fetchProduct();
      } else {
        setLoading(false);
      }
    }
  }, [product_types]);

  useEffect(() => {
    if (productTypes.productTypes) {
      setproduct_types(productTypes.productTypes.data);
    }
    if (productTypes?.productTypesError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productTypes?.productTypesError!?.response!?.data!?.message
          ? productTypes?.productTypesError!?.response!?.data!?.message
          : productTypes?.productTypesError!?.message
      );
    }
  }, [productTypes]);

  useEffect(() => {
    if (productState.createProduct) {
      const product = productState.createProduct;
      destroy();
      productDispatch({ type: CREATE_PRODUCT, payload: null });
      history.push({
        pathname: `/dashboard/items/detail?q=${
          product.id
        }&i=${product.name.slice(0, 30)}`,
        state: { id: product.id },
      });
    }
    if (productState.createProductError) {
      setIsErrorOpen(true);
      setErrorMessage(productState.createProductError);
      setLoading(false);
      productDispatch({ type: CREATE_PRODUCT_ERROR, payload: null });
    }

    if (productState.getSingleProduct && location.pathname.includes('edit')) {
      const { price, ...rest } = productState.getSingleProduct;
      setItem({ ...rest, price: price.toString() });
    }
    if (
      productState?.getSingleProductError &&
      location.pathname.includes('edit')
    ) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.getSingleProductError!?.response!?.data!?.message
          ? productState?.getSingleProductError!?.response!?.data!?.message
          : productState?.getSingleProductError!?.message
      );
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
    }
    if (productState.updateProduct) {
      const product = productState.updateProduct.data;
      // clear all state variables
      destroy();
      async function fetchItem() {
        await getSingleProduct(item?.id!)(productDispatch);
      }
      fetchItem();
      history.push({
        pathname: `/dashboard/items/detail?q=${
          product.id
        }&i=${product.name.slice(0, 30)}`,
        state: { id: product.id },
      });
      productDispatch({ type: UPDATE_PRODUCT, payload: null });
    }

    if (productState.updateProductError) {
      setIsErrorOpen(true);
      setErrorMessage(productState.updateProductError);
      setLoading(false);
      productDispatch({ type: UPDATE_PRODUCT_ERROR, payload: null });
    }
  }, [productState, productDispatch, location.pathname]);

  async function saveItem() {
    const valid = validate();
    if (!valid) {
      setIsErrorOpen(true);
      setErrorMessage('Please Fill all Required fields before saving');
      return;
    }
    const itemData = {
      ...item,
      status: 'publish',
      tags: [],
      price: Number(item.price.replace(/,/g, '')),
    };
    setLoading(true);
    const path = location.pathname;
    let uploadedImages: string[] = [];
    const newImages = itemData.images.filter((e) => e instanceof File);
    if (newImages.length) {
      try {
        uploadedImages = await handleImageUpload(newImages).then((s) => s);
      } catch (err) {
        return;
      }
    }
    itemData.images = [
      ...uploadedImages,
      ...itemData.images.filter((e) => !(e instanceof File)),
    ];
    const { id, ...rest } = itemData;

    if (path.includes('edit-product')) {
      return await updateProduct({
        id: id!.toString(),
        data: { ...rest },
      })(productDispatch);
    } else {
      return await createProduct({ ...itemData })(productDispatch);
    }
  }
  const handleImageUpload = async (images: File[]) => {
    const uploadImages = await uploadFile({
      files: images,
      folder: FolderEnum.Products,
    });

    if (uploadImages.error) {
      setIsErrorOpen(true);
      setErrorMessage(uploadImages.error);
      setLoading(false);
      throw new Error();
    }
    setUploadedImages(uploadImages as string[]);
    return uploadImages;
  };

  async function saveDraft() {
    if (!item.name?.trim().length) {
      setIsErrorOpen(true);
      setErrorMessage('Please fill Name field before saving draft');
      return;
    }
    const draft = {
      ...item,
      tags: [],
      status: 'draft',
      price: Number(item.price?.replace(/,/g, '')) | 0,
    };
    setLoading(true);
    let uploadedImages: string[] = [];
    const newImages = draft.images.filter((e) => e instanceof File);
    if (newImages.length) {
      try {
        uploadedImages = await handleImageUpload(newImages).then((s) => s);
      } catch (err) {
        return;
      }
    }
    draft.images = [
      ...draft.images.filter((e) => !(e instanceof File)),
      ...uploadedImages,
    ];
    if (location.pathname.includes('edit-product')) {
      const { id, ...rest } = draft;
      return await updateProduct({ id: id!?.toString(), data: { ...rest } })(
        productDispatch
      );
    }
    await createProduct(draft)(productDispatch);
  }
  async function fetchProduct() {
    const productID: any = {
      id: location.search.split('&')[0].split('=')[1],
    };
    if (!productID.hasOwnProperty('id')) {
      return history.replace({
        pathname: '/err',
        state: {
          from: '/dashboard/store',
          err: 'Something Went Wrong!',
          code: 404,
        },
      });
    }
    if (
      productState.getSingleProduct?.hasOwnProperty('id') &&
      productState.getSingleProduct?.id === Number(productID!?.id)
    ) {
      const { price, ...rest } = productState.getSingleProduct;
      return setItem({ ...rest, price: price.toString() });
    }
    await getSingleProduct(productID.id)(productDispatch);
  }

  const validate = (): boolean => {
    if (
      item.name?.trim().length &&
      item.price?.toString().length &&
      item.details?.replace(/<[^>]+>/g, '').trim().length &&
      item.type?.length &&
      item.images?.length
    ) {
      return true;
    }
    return false;
  };
  const destroy = async () => {
    setItem({} as Omit<ProductType, 'price'> & { price: string });
    setUploadedImages([]);
    setLoading(false);
  };

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div id="pageContainer">
        {loading ? (
          <div className={classes.loading}>
            <Spinner type="circles" />
          </div>
        ) : (
          <div className="w-full h-full">
            <div
              className={
                'w-full h-auto px-4 border-b-2 bg-white grid grid-cols-2 grid-flow-row-dense gap-0'
              }
            >
              <div className="h-auto w-full md:w-fit flex flex-row items-center md:py-4 pt-4 pb-2 col-span-2 md:col-span-1">
                <IonButton
                  fill="outline"
                  // color="primary"
                  onClick={() => {
                    router.goBack();
                  }}
                  style={{
                    '--color': 'var(--ion-color-primary)',
                    '--border-radius': '8px',
                    '--border-color': 'rgba(15, 50, 77, 0.4)',
                    '--border-width': '1px',
                    '--padding-start': '0.8em',
                    '--padding-end': '0.8em',
                  }}
                  size="default"
                >
                  <IonIcon
                    slot="icon-only"
                    size="small"
                    icon={arrowBackSharp}
                  ></IonIcon>
                </IonButton>
                <span className="ml-4 text-[var(--ion-color-primary)] font-['Roboto', sans-serif] font-semibold text-lg">
                  {location.pathname.includes('edit')
                    ? 'Edit Product'
                    : 'Add New Product'}
                </span>
              </div>
              <div className="h-fit w-full md:w-fit flex flex-row items-center justify-evenly py-4 ml-auto col-span-2 md:col-span-1">
                <IonButton
                  fill={
                    classNames({
                      outline: size.width >= 768,
                      clear: size.width < 768,
                    }) as 'outline' | 'clear'
                  }
                  color="secondary"
                  onClick={saveDraft}
                  className="w-[45%] md:w-auto"
                  title={'Save As Draft'}
                  style={{
                    '--border-radius': '8px',
                    '--border-width': '1px',
                    '--padding-start': '0.8em',
                    '--padding-end': '0.8em',
                  }}
                  size="default"
                >
                  <IonIcon
                    slot={'start'}
                    size="small"
                    icon={saveSharp}
                  ></IonIcon>
                  <span>Save As Draft</span>
                </IonButton>
                <IonButton
                  fill="solid"
                  color="secondary"
                  onClick={saveItem}
                  title="Save New Product"
                  className="md:ml-4 ml-2 w-[45%] md:w-auto"
                  style={{
                    '--border-radius': '8px',
                    '--border-width': '1px',
                    '--padding-start': '0.8em',
                    '--padding-end': '0.8em',
                    '--padding-top': '0.5em',
                    '--padding-bottom': '0.5em',
                  }}
                  size="default"
                >
                  Publish
                </IonButton>
              </div>
            </div>
            <div className="w-full h-[85%] px-4 py-2 grid grid-cols-3 grid-flow-row-dense gap-2 overflow-y-scroll">
              <div
                className={
                  'w-auto h-fit p-2 bg-white border-2 rounded-md ' +
                  classNames({
                    'col-span-3': size.width < 768,
                    'col-span-1': size.width >= 768,
                  })
                }
              >
                <div className="w-full h-auto text-[var(--ion-color-primary)] text-lg font-semibold font-['Roboto', sans-serif] py-2">
                  Product Images
                </div>
                <div className="w-full h-auto aspect-[1/1.1] flex flex-row p-2">
                  <div className="size-full bg-gray-300 rounded-lg">
                    <ImageCarouselInput
                      handleInput={(v, n) => {
                        setItem({ ...item, [n]: [...v] });
                      }}
                      editable
                      images={
                        location.pathname.includes('edit')
                          ? item.images
                          : undefined
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  'w-auto h-fit p-2 bg-white border-2 rounded-md  grid grid-cols-2 grid-flow-row-dense gap-1 ' +
                  classNames({
                    'col-span-3': size.width < 768,
                    'col-span-2': size.width >= 768,
                  })
                }
              >
                <div className={'w-full h-auto py-2 md:px-4 px-2 col-span-2'}>
                  <IonLabel
                    color="primary"
                    className="w-full h-auto font-semibold text-sm"
                  >
                    Name
                  </IonLabel>
                  <div className="w-full h-auto ">
                    <InputCard
                      inputType="text-field"
                      name="name"
                      placeholder="Enter Product Name"
                      required
                      type="text"
                      value={setItem}
                      borders
                      clear
                      formValues={item}
                      maxLength={256}
                    />
                  </div>
                </div>
                <div
                  className={
                    'w-full h-auto py-2 md:px-4 px-2 md:col-span-1 col-span-2'
                  }
                >
                  <IonLabel
                    color="primary"
                    className="w-full h-auto font-semibold text-sm"
                  >
                    Price
                  </IonLabel>
                  <div className="w-full h-auto flex items-center border-[1px] px-2 rounded-xl border-gray-400">
                    <div className="w-auto h-auto font-medium text-sm mr-2 text-[var(--ion-color-primary)]">
                      UGX
                    </div>
                    <div className="w-[100%]">
                      <InputCard
                        inputType="text-field"
                        name="price"
                        placeholder="Product Price"
                        required
                        type="number"
                        value={setItem}
                        clear
                        formValues={item}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'w-full h-auto py-2 md:px-4 px-2 md:col-span-1 col-span-2'
                  }
                >
                  <IonLabel
                    color="primary"
                    className="w-full h-auto font-semibold text-sm"
                  >
                    Category
                  </IonLabel>
                  <div className="w-full h-auto ">
                    <SelectField
                      name="type"
                      options={product_types}
                      placeholder="Select Product Category"
                      value={setItem}
                      formValues={item}
                      borders
                    />
                  </div>
                </div>
                <div className={'w-full h-auto py-2 md:px-4 px-2 col-span-2'}>
                  <IonLabel
                    color="primary"
                    className="w-full h-auto font-semibold text-sm"
                  >
                    Details
                  </IonLabel>
                  <div className="w-full h-auto md:aspect-[1/0.7] aspect-[1/1.8] ">
                    <RichTextInput
                      handleChange={(v, n) => {
                        setItem({ ...item, [n]: v });
                      }}
                      value={item.details!}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreateEditProduct;
