import { Dispatch } from 'react';
import { axiosInstance } from '../../../utils/axios';
import { GET_ADMIN_USER, GET_ADMIN_USER_ERROR, GET_ADMIN_USERS, GET_ADMIN_USERS_ERROR } from '../types';

export const getAdminUsers = () => async(dispatch : Dispatch<{type: string, payload: any}>)=>{
    try{
const res = await axiosInstance.get(`v1/admin/users`)
     dispatch({type: GET_ADMIN_USERS, payload: res.data.data})
    }catch(error: any){
    dispatch({type: GET_ADMIN_USERS, payload: error.response.data.message || error.message})

    }
}

export const getAdminUser = (id: number | string) => async(dispatch : Dispatch<{type: string, payload: any}>)=>{
    try{
const res = await axiosInstance.get(`v1/admin/users/${id}`)
     dispatch({type: GET_ADMIN_USER, payload: res.data.data})
    }catch(error: any){
    dispatch({type: GET_ADMIN_USER, payload: error.response.data.message || error.message})

    }
}