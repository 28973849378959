import {
  GET_ADMIN_USER,
  GET_ADMIN_USER_ERROR,
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_ERROR,
} from '../../actions/types';

export interface adminUserState {}

export const adminUserInitialState: adminUserState = {
    adminUsers: null,
    adminUsersError: null,
    adminUser: null,
    adminUserError: null,
}

const adminUserReducer = (
  state: adminUserState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ADMIN_USERS:
      return { ...state, adminUsers: action.payload, adminUsersError: null };
    case GET_ADMIN_USERS_ERROR:
      return { ...state, adminUsers: null, adminUsersError: action.payload };
    case GET_ADMIN_USER:
        return { ...state, adminUser: action.payload, adminUserError: null };
    case GET_ADMIN_USER_ERROR:
        return { ...state, adminUser: null, adminUserError: action.payload };
    default:
      return state;
  }
};

export default adminUserReducer;
