import { useParams } from 'react-router';
import Layout from '../../../../components/Layout/admin';
import classes from './styles.module.scss';
import { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../../context/provider';
import {
  approveRejectStore,
  getAdminStore,
} from '../../../../context/actions/admin/store';
import {
  GET_ADMIN_STORE,
  GET_ADMIN_STORE_ERROR,
  APPROVE_REJECT_STORE,
  APPROVE_REJECT_STORE_ERROR,
} from '../../../../context/actions/types';
import ErrorToast from '../../../../components/error_toast';
import Spinner from '../../../../components/spinner';
import { HiChevronLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';

interface Admin {
  id: number | string;
  name: string;
  type: string;
  status: string;
  createdAt: string;
  email: string;
  phoneNumber: string;
  description: string;
  location: string;
  logo: string;
}

const AdminStoreDetails: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const { adminStoreState, adminStoreDispatch } =
    useContext<any>(GlobalContext);
  const [store, setStore] = useState<Admin>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [actionError, setActionError] = useState<{
    state: boolean;
    message: string | null;
  }>({
    state: false,
    message: null,
  });

  useEffect(() => {
    fetchAdminStore();
  }, []);

  useEffect(() => {
    if (adminStoreState?.adminStore) {
      const store = adminStoreState?.adminStore;
      setStore(store);
      adminStoreDispatch({ type: GET_ADMIN_STORE, payload: null });
    }
    if (adminStoreState?.getAdminStoreError) {
      setShowError(true);
      setErrorMessage(adminStoreState?.getAdminStoreError);
      adminStoreDispatch({ type: GET_ADMIN_STORE_ERROR, payload: null });
    }
  }, [adminStoreState, adminStoreDispatch]);

  useEffect(() => {
    if (adminStoreState?.approveRejectStore) {
      const store = adminStoreState?.approveRejectStore;
      setStore(store);
      adminStoreDispatch({ type: APPROVE_REJECT_STORE, payload: null });
    }
    if (adminStoreState?.approveRejectStoreError) {
      setActionError({
        state: true,
        message: adminStoreState?.approveRejectStoreError,
      });
      adminStoreDispatch({ type: APPROVE_REJECT_STORE_ERROR, payload: null });
    }
  }, [adminStoreState, adminStoreDispatch]);

  const fetchAdminStore = async () => {
    setLoading(true);
    await getAdminStore(id!)(adminStoreDispatch);
    setLoading(false);
  };

  const handleStoreAction = async (status: string) => {
    if (status === 'active') {
      setApproveLoading(true);
      const payload = { storeId: Number(id), status };
      await approveRejectStore(payload)(adminStoreDispatch);
      setApproveLoading(false);
    } else if (status === 'deactivated') {
      setRejectLoading(true);
      const payload = { storeId: Number(id), status };
      await approveRejectStore(payload)(adminStoreDispatch);
      setRejectLoading(false);
    }
  };

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={showError}
        onClose={() => {
          setShowError(false);
        }}
      />
      <div className={classes.header}>
        {actionError && <p className={classes.error}>{actionError.message}</p>}
        <div className={classes.sec}>
          <Link to="/admin/dashboard/stores">
            <HiChevronLeft className={classes.arrow} />
          </Link>
          <p className={classes.heading}>Store Details</p>
        </div>
        <div className={classes.buttons}>
          <div className={classes.sec1}>
            <p className={classes.status}>status:</p>
            {store?.status ? (
              <div
                className={classes.pend}
                style={{
                  backgroundColor:
                    store?.status === 'pending'
                      ? '#FFE9D2'
                      : store?.status === 'active'
                      ? '#b7f3c5'
                      : store?.status === 'deactivated'
                      ? '#e6bab8'
                      : 'gray',
                  color:
                    store?.status === 'pending'
                      ? '#D68800'
                      : store?.status === 'active'
                      ? '#28A745'
                      : store?.status === 'deactivated'
                      ? '#E53A34'
                      : 'white',
                }}
              >
                {store.status}
              </div>
            ) : (
              <></>
            )}
          </div>
          {store?.status === 'pending' ? (
            <div className={classes.sec2}>
              <button
                className={classes.approve}
                onClick={() => handleStoreAction('active')}
                disabled={approveLoading}
              >
                {approveLoading ? (
                  <IonSpinner className={classes.spinner} />
                ) : (
                  'APPROVE'
                )}
              </button>
              <button
                className={classes.reject}
                onClick={() => handleStoreAction('deactivated')}
                disabled={rejectLoading}
              >
                {rejectLoading ? (
                  <IonSpinner className={classes.spinner} />
                ) : (
                  'REJECT'
                )}
              </button>
            </div>
          ) : store?.status === 'active' ? (
            <div className={classes.sec2}>
              <button
                className={classes.reject}
                onClick={() => handleStoreAction('deactivated')}
                disabled={rejectLoading}
              >
                {rejectLoading ? (
                  <IonSpinner className={classes.spinner} />
                ) : (
                  'DEACTIVATE'
                )}
              </button>
            </div>
          ) : store?.status === 'deactivated' ? (
            <div className={classes.sec2}>
              <button
                className={classes.approve}
                onClick={() => handleStoreAction('active')}
                disabled={approveLoading}
              >
                {approveLoading ? (
                  <IonSpinner className={classes.spinner} />
                ) : (
                  'ACTIVATE'
                )}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.main}>
        {loading && (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        )}
        <div className={classes.banner}>
          {store?.logo ? (
            <img className={classes.img} src={store?.logo} alt="" />
          ) : (
            <div className={classes.noImage}></div>
          )}
        </div>
        <div className={classes.details}>
          <div className={classes.para2}>
            <p>ID</p>
            <p>Name</p>
            <p>Category</p>
            <p>Email</p>
            <p>Phone Number(s)</p>
            <p>Location(s)</p>
            <p>Description</p>
          </div>
          {store && (
            <div className={classes.para3}>
              <p>{id}</p>
              <p>{store.name}</p>
              <p>{store.type}</p>
              <p>{store.email}</p>
              <p>{store.phoneNumber}</p>
              <p>{store.location}</p>
              <p>{store.description}</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminStoreDetails;
