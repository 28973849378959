import { useEffect, useState } from 'react';
import { FaqsType } from '../../types/faqs';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { IonRippleEffect } from '@ionic/react';

const FaqsItem = ({ title, content, position }: FaqsType) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (position === 0) {
      setToggle(!toggle);
    }
  }, []);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div className={'w-full h-fit border-b-[1px] border-[#10324d9d]'}>
      <div
        className={
          ' relative px-2 w-full h-[4rem] flex items-center justify-between ion-activatable ripple-parent circle cursor-pointer'
        }
        onClick={handleToggle}
      >
        <IonRippleEffect></IonRippleEffect>
        <h4 className="m-0 p-0 text-lg font-semibold font-['Roboto', sans-serif] text-[var(--ion-color-primary)]">
          {title}
        </h4>
        <div className="h-full w-auto aspect-square flex items-center justify-center">
          {!toggle ? (
            <HiChevronDown className={'text-[var(--ion-color-primary)] '} />
          ) : (
            <HiChevronUp className={'text-[var(--ion-color-primary)] '} />
          )}
        </div>
      </div>
      {toggle ? (
        <div className="w-full h-auto px-6 pb-4">
          {content.map((item, index) => (
            <p
              key={index}
              className={
                'w-full h-auto text-[var(--ion-color-primary)] text-base font-["Roboto",sans-serif]'
              }
            >
              {item}
            </p>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default FaqsItem;
