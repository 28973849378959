import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonIcon,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRippleEffect,
} from '@ionic/react';
import {
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import InputCard from '../inputField';
import { caretDownSharp, caretUpSharp } from 'ionicons/icons';
import classNames from 'classnames';
import { DeviceContext } from '../../deviceType';

const SelectField = ({
  value,
  name,
  placeholder,
  options,
  mode,
  formValues,
  multiple = false,
  borders,
}: {
  value: (arg0: (form: any) => any) => void;
  name: string;
  placeholder: string;
  options: string[];
  mode?: 'ios' | 'md';
  formValues?: any;
  multiple?: boolean;
  borders?: boolean;
}) => {
  const size = useContext(DeviceContext);
  const [data, setData] = useState<{ query: string }>({} as { query: string });
  const [open, setOpen] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<string[]>(options);
  const [selected, setSelected] = useState<{
    single: (typeof options)[number];
    list: (typeof options)[number][];
  }>({ single: '', list: [] });
  const modal = useRef<any>(null);

  useEffect(() => {
    if (data.query) {
      const _filtered = options.filter((item) => {
        return item.toLowerCase().includes(data.query.toLowerCase());
      });
      setFiltered(_filtered);
    } else {
      setFiltered(options);
    }
  }, [data]);

  useEffect(() => {
    if (formValues[name]) {
      if (multiple) {
        return setSelected({ ...selected, list: formValues[name] });
      }
      return setSelected({ ...selected, single: formValues[name] });
    }
  }, []);
  useEffect(() => {
    if (options.length) {
      setFiltered(options);
    }
  }, [options]);

  const closeModal = () => {
    setSelected({ single: '', list: [] });
    modal.current.dismiss();
  };
  const save = () => {
    value((form) => {
      return {
        ...form,
        [name]: multiple ? selected.list : selected.single,
      };
    });
    modal.current.dismiss();
  };
  const handleRadioItemClick: any = (e: Event) => {
    let parent: EventTarget;
    if ((e.target as any).localName === 'span') {
      parent = (e.target as any).offsetParent;
    } else {
      parent = e!?.target!;
    }
    const child = (parent as any).querySelector('ion-radio');
    if (child) {
      child.click(); // Simulates a click event on the child
    }
  };
  const handleCheckItemClick: any = (e: Event) => {
    const child = (e.target as any).querySelector('ion-checkbox');
    if (child) {
      child.click();
    }
  };
  return (
    <>
      <IonModal
        trigger={`select-${name}-modal-trigger`}
        ref={modal}
        onDidDismiss={() => {
          setOpen(false);
        }}
        onDidPresent={() => {
          setOpen(true);
        }}
        style={{
          '--width': 'fit-content',
          '--min-width': classNames({
            '80dvw': size.width < 768,
            '20dvw': size.width >= 768,
          }),
          '--height': 'fit-content',
          '--border-radius': '10px',
          '--box-shadow': '0 28px 48px rgba(0, 0, 0, 0.4)',
        }}
      >
        <div className="w-full h-fit flex flex-col items-center px-4 py-2 ">
          <div className="w-full h-fit flex flex-row items-center py-2 border-b-2">
            <InputCard
              inputType="text-field"
              name="query"
              placeholder="Search"
              required
              type="search"
              value={setData}
              borders
              clear
            />
          </div>
          <div
            className={
              'w-full h-auto overflow-y-scroll px-2 my-2 ' +
              classNames({
                'max-h-[40dvh]': size.width >= 768,
                'max-h-[55dvh]': size.width < 768,
              })
            }
          >
            {!multiple && (
              <IonRadioGroup
                value={selected.single}
                onIonChange={(e) =>
                  setSelected({ ...selected, single: e.detail.value })
                }
                className="flex flex-col justify-center items-start "
              >
                {filtered.map((i, ind) => {
                  return (
                    <div
                      onClick={handleRadioItemClick}
                      className="hover:bg-gray-50 ion-activatable ripple-parent circle relative w-full h-fit flex flex-row items-center justify-start px-2 py-2 cursor-pointer"
                    >
                      <IonRadio value={i}></IonRadio>
                      <span
                        onClick={(e) => {}}
                        className="w-auto ml-2 text-[var(--ion-color-primary)]  font-medium"
                      >
                        {i}
                      </span>
                      <IonRippleEffect></IonRippleEffect>
                    </div>
                  );
                })}
              </IonRadioGroup>
            )}
            {multiple && (
              <div className=" w-full flex flex-col justify-center items-start ">
                {filtered.map((i, ind) => {
                  return (
                    <div
                      key={ind}
                      onClick={handleCheckItemClick}
                      className={
                        'hover:bg-gray-50 ion-activatable ripple-parent circle relative w-full h-fit flex flex-row items-center justify-start px-2 py-2 cursor-pointer'
                      }
                    >
                      <IonCheckbox
                        slot="start"
                        checked={selected.list.includes(i)}
                        style={{ '--border-radius': '5px' }}
                        color="primary"
                        onClick={(e) => {
                          if (selected.list.includes(i)) {
                            setSelected({
                              ...selected,
                              list: [...selected.list.filter((s) => s !== i)],
                            });
                          } else {
                            setSelected({
                              ...selected,
                              list: [...selected.list, i],
                            });
                          }
                        }}
                      />
                      <span
                        onClick={(e) => {}}
                        className="w-auto ml-2 text-[var(--ion-color-primary)] font-medium"
                      >
                        {i}
                      </span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="w-full h-fit flex flex-row items-center justify-end mt-auto border-t-2 py-2">
            <IonButton
              fill={'outline'}
              color="primary"
              onClick={closeModal}
              style={{
                '--border-radius': '8px',
                '--border-width': '1px',
                '--padding-start': '0.8em',
                '--padding-end': '0.8em',
              }}
              size="default"
            >
              Cancel
            </IonButton>
            <IonButton
              fill={'solid'}
              color="primary"
              onClick={save}
              style={{
                '--border-radius': '8px',
                '--border-width': '1px',
                '--padding-start': '0.8em',
                '--padding-end': '0.8em',
              }}
              size="default"
            >
              Save
            </IonButton>
          </div>
        </div>
      </IonModal>
      <IonCard
        className={
          'w-full h-auto rounded-xl m-0 p-0 ' +
          classNames({
            'border-2-[var(--ion-color-primary)]': borders,
            'border-0': !borders,
          })
        }
        mode={mode}
      >
        <IonCardContent
          id={`select-${name}-modal-trigger`}
          className="relative w-full h-auto flex flex-row items-center px-4 py-0 ion-activatable ripple-parent circle cursor-pointer"
        >
          <IonRippleEffect></IonRippleEffect>
          <span
            className={
              ' max-w-[80%] text-lg text-[var(--ion-color-primary)] font-["Roboto", sans-serif] font-medium line-clamp-1 text-ellipsis text-nowrap ' +
              classNames({
                flex:
                  (!multiple && selected.single) ||
                  (multiple && selected.list.length),
                hidden:
                  (!multiple && !selected.single) ||
                  (multiple && !selected.list.length),
              })
            }
          >
            {!multiple ? selected.single : selected.list.join(',')}
          </span>
          <span
            className={
              'contents text-lg text-gray-500 font-["Roboto" sans-serif] font-medium ' +
              classNames({
                hidden:
                  (!multiple && selected.single) ||
                  (multiple && selected.list.length),
              })
            }
          >
            {placeholder}
          </span>
          <IonButton
            fill="clear"
            color="primary"
            style={{
              '--padding-start': '0.8em',
              '--padding-end': '0.8em',
            }}
            className="ml-auto"
          >
            <IonIcon
              slot="icon-only"
              icon={open ? caretUpSharp : caretDownSharp}
              size="small"
            />
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default SelectField;
