import { useContext, useState } from 'react';
import classes from './styles.module.scss';
import Footer from '../../../components/Layout/user/footer';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
} from '@ionic/react';
import Nav from '../../../components/Layout/user/nav';
import landing_img from '../../../Assets/landing_image.svg';
import { useHistory } from 'react-router-dom';
import ContactUsForm from '../../../components/contactusForm/contact';
import FaqsItem from '../../../components/Faqs';
import { FaqsType } from '../../../types/faqs';
import { IoMdArrowDropright } from 'react-icons/io';
import { faqs } from '../../../data/faqs';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../../../utils/getClientInfo';
import BecomeASellerModal from '../../../components/becomeSellerModal';
import AddToHomeScreen from '../../../components/AddToHomeScreen';
import { LandingPageData } from '.';
import logo from '../../../Assets/logo/logo_white_background_96.svg';

import { AboutRoutes } from '../../../utils/user/routes';
import classNames from 'classnames';
import { DeviceContext } from '../../../deviceType';

const Desktop = ({ data, video }: { data: LandingPageData; video: any }) => {
  const size = useContext(DeviceContext);
  const history = useHistory();
  const userData = getCurrentUser();
  const isLoggedIn = !!Object.keys(userData).length;
  const isVendor = isLoggedIn && userData?.role === 'seller';
  const isBuyer = isLoggedIn && userData?.role === 'user';
  const hasStore = isVendor && userData?.stores?.length;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const filterQuestions = faqs.filter((item: FaqsType, index: number) => {
    return index <= 3;
  });

  const onCreateStoreBtnClick = () => {
    if (!isLoggedIn) {
      // history.push('/registervendor');
      history.push('/signup');
    } else if (isBuyer) {
      setIsModalOpen(true);
    } else if (isVendor && !hasStore) {
      history.push('/dashboard/create-store');
    } else if (isVendor && hasStore) {
      history.push('/dashboard/store');
    }
  };

  const getCreateStoreBtnText = () => {
    if (isLoggedIn && isBuyer) {
      return 'Become a Vendor';
    } else if (isLoggedIn && isVendor && hasStore) {
      return 'Manage your shop';
    }
    return 'Create Account';
  };

  return (
    <div className="w-full h-[100dvh] overflow-y-scroll">
      {/* Become vendor modal */}
      <BecomeASellerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <div
        className={
          classes.nav +
          ' size-full md:px-4 w-full bg-gray-50 h-[10%] fixed z-10 md:py-2'
        }
      >
        <Nav
          backgroundColor={
            classNames({
              transparent: size.width >= 768,
              primary: size.width < 768,
            }) as 'primary' | 'white' | 'transparent'
          }
        />
      </div>
      {/* content */}
      <div className={'mt-[10dvh] relative w-full h-auto overflow-x-hidden'}>
        {/* showcase */}
        <div className="w-full h-auto md:h-[88dvh] grid grid-cols-2 grid-flow-row-dense gap-2">
          <div className="h-[60dvh] md:h-full w-auto col-span-2 md:col-span-1">
            <div
              className={
                'h-full px-6 md:px-0 md:pl-[12.3%] w-full flex flex-col  justify-center'
              }
            >

              <div className="text-[var(--ion-color-primary)] text-3xl font-bold py-2 text-center md:text-start">
                {data.showcase!.primary}
              </div>
              <div className="text-[var(--ion-color-secondary)] text-xl font-base  text-center md:text-start">
                {data.showcase!.secondary}
              </div>
              <div className="mt-6 md:w-[40%] w-[80%] mx-auto md:mx-0 h-[3rem]">
                <IonButton
                  className={'!size-full m-0 p-0'}
                  fill="solid"
                  color="secondary"
                  onClick={onCreateStoreBtnClick}
                >
                  {getCreateStoreBtnText()}
                </IonButton>
                {/* {
                      !isLoggedIn &&
                      <IonButton
                        className={classes.btn}
                        fill="clear"
                        color="secondary"
                        routerLink="/signup"
                      >
                        Join as a Shopper
                      </IonButton>
                    } */}
              </div>
            </div>
          </div>
          <div className="h-[90dvh] md:h-full w-auto overflow-hidden bg-[var(--ion-color-primary)] rounded-b-full md:rounded-b-[0] md:!rounded-s-[5rem]   col-span-2 md:col-span-1">
            <div className="w-[60%] md:w-[40%] md:h-[70%] h-[50%] flex flex-col items-center justify-center bg-[var(--ion-color-secondary)] rounded-b-full mx-auto">
              <div className={'w-auto h-[90%] aspect-[9/16] px-4 '}>
                <IonImg src={landing_img}></IonImg>
              </div>
            </div>
            <div className="w-full md:w-[70%] md:mx-auto pb-4 md:pb-0 h-[40%] md:h-[30%] grid grid-cols-2  grid-flow-row-dense gap-2">
              {data!.showcase?.showcase_stat.map((item, index: number) => (
                <div
                  className={
                    'h-auto w-[60%] md:w-auto mx-auto md:mx-0 flex flex-col items-center md:col-span-1 col-span-2'
                  }
                  key={index}
                >
                  <div className="w-auto h-auto pb-1 text-[var(--ion-color-secondary)]  font-semibold text-xl">
                    {item.label}
                  </div>
                  <div className="w-[15%] h-auto aspect-square flex items-center justify-center pb-1">
                    <IonIcon
                      icon={item.icon as string}
                      color="light"
                      className="size-full"
                    ></IonIcon>
                  </div>
                  <div className="w-auto h-auto pb-1 text-[var(--ion-color-secondary)]  font-semibold text-xl">
                    {item.statistic}
                  </div>
                  <div className="text-center w-auto h-[20%] pb-1 text-white  text-sm px-2">
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* why us */}
        <div className={'w-full h-auto px-[6%] py-4 pt-12 '}>
          <div
            className={
              'w-full md:w-auto h-auto py-2 text-[var(--ion-color-secondary)] text-2xl font-bold  text-center md:text-start'
            }
          >
            {data.whyus!.label}
          </div>
          <div className="w-auto h-auto py-2 text-[var(--ion-color-primary)] text-xl font-base  text-center md:text-start">
            {data.whyus!.sub}
          </div>
          <div className="w-full h-auto py-6 pb-12 grid grid-cols-4 grid-flow-row-dense gap-2">
            {data.whyus!.list.map((e) => {
              return (
                <div className="h-auto flex flex-col items-center px-4 col-span-4 md:col-span-1">
                  <IonIcon
                    src={e.icon}
                    className="aspect-square h-auto w-[30%] my-2"
                  ></IonIcon>
                  <div className=" text-center w-full text-[var(--ion-color-primary)] font-semibold text-lg">
                    {e.name}
                  </div>
                  <div className="text-center w-full text-[var(--ion-color-primary)]  font-base text-base">
                    {e.body}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* become a vendor */}
        <div className="w-full h-fit px-8 md:px-[6%] py-4 grid grid-cols-2 grid-flow-row-dense gap-2">
          <div className="w-full h-auto md:h-full overflow-hidden md:pt-12 col-span-2 md:col-span-1">
            <div
              className={
                'w-full md:w-auto h-auto py-2 text-[var(--ion-color-secondary)] text-2xl font-bold  text-center md:text-start'
              }
            >
              {data.becomevendor!.label}
            </div>
            <div className=" w-full md:w-auto h-auto py-2 text-[var(--ion-color-primary)] text-base font-base text-center md:text-start">
              {data.becomevendor!.sub}
            </div>
          </div>
          <div className="w-full h-auto aspect-video p-0 md:p-4 col-span-2 md:col-span-1">
            <div className="size-full overflow-hidden rounded-3xl md:rounded-[2rem]">
              <video
                ref={video}
                src={data.becomevendor!.video}
                className="size-full object-cover"
                controls
                autoPlay
                loop
              ></video>
            </div>
          </div>
        </div>
        {/* FAQS */}
        <div className={'w-full h-auto px-[6%] py-4 '}>
          <div
            className={
              'w-full md:w-auto h-auto py-2 text-[var(--ion-color-secondary)] text-2xl font-bold  text-center md:text-start'
            }
          >
            {data.faqs!.label}
          </div>
          <div className={'w-full h-auto py-4'}>
            {filterQuestions.map((item: FaqsType, index: number) => {
              return (
                <FaqsItem
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              );
            })}
          </div>
          <div className="w-full h-auto py-2 flex item-center justify-center">
            <Link
              to={'/faqs'}
              className={
                'text-[var(--ion-color-secondary)] font-semibold text-sm mx-2'
              }
            >
              View More
            </Link>
          </div>
        </div>
        {/* contact us */}
        <div className="w-full h-auto p-4" id="contactus">
          <div className="w-full h-auto bg-[var(--ion-color-primary-shade)] rounded-[2rem] md:rounded-[3rem] py-6 px-4 md:py-10 md:px-[4.6%] grid grid-cols-2 grid-flow-row-dense gap-2 pb-10">
            <div className=" col-span-2 w-auto h-auto py-2 text-[var(--ion-color-secondary)] text-2xl font-bold  text-center md:text-start">
              {data.contact!.label}
            </div>
            <div
              className={
                'col-span-2 order-2 md:order-1 md:col-span-1 md:px-4 flex justify-center md:justify-start border-r-0 md:border-r-2 border-white'
              }
            >
              <ContactUsForm />
            </div>
            <div className=" col-span-2 md:col-span-1 px-4 md:px-10 order-1 md:order-2">
              <div className="text-white text-lg font-medium  py-2 text-center md:text-start">
                {data.contact!.sub}
              </div>
              <div className=" py-2 flex flex-col items-start">
                {data.contact!.contacts.map((e) => {
                  return (
                    <div
                      key={e}
                      className="w-full text-white text-lg font-medium  pb-2 text-center md:text-start"
                    >
                      {e}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddToHomeScreen />
      {/* footer */}
      <div className="w-full h-auto mt-auto px-6 pb-[2.5rem]">
        <div className="w-full h-auto flex items-center justify-between mb-4 mt-8">
          <div className="h-auto w-[50%] flex items-center ">
            <IonIcon
              src={logo}
              className="aspect-square h-auto w-[2rem] md:w-[6%] mx-2"
            ></IonIcon>
            <span className="text-[var(--ion-color-primary)]  font-bold text-lg">
              Bazaar
            </span>
            <span className="text-[var(--ion-color-secondary)] italic  font-bold text-lg">
              Cheap
            </span>
          </div>
          <div className="flex items-center  w-auto h-full">
            {AboutRoutes.map((item, index) => {
              return (
                <Link
                  to={item.route}
                  className={
                    'text-[var(--ion-color-primary)]  font-semibold text-sm mx-2'
                  }
                  key={index}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="w-full h-auto border-t-2 border-gray-200 flex items-center justify-between">
          <div className="text-gray-400  font-medium text-sm py-4 px-2">
            Copyright &copy; Bazaar Cheap {new Date().getFullYear()}
          </div>
          <div className="w-auto h-full">
            <IonButton
              onClick={() => {}}
              fill="clear"
              className="text-gray-500  font-medium text-sm"
            >
              Terms of Use
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;