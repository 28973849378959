import Layout from '../../../components/Layout/admin';
import Header from '../header';
import classes from './styles.module.scss';
import { HiChevronDown } from 'react-icons/hi';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import { GlobalContext } from '../../../context/provider';
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect, useCallback } from 'react';
import { GET_ADMIN_PRODUCTS } from '../../../context/actions/types';
import { getAdminProducts } from '../../../context/actions/admin/product';

interface Admin {
  id: number;
  name: string;
  type: string;
  status: string;
  createdAt: string;
}

const Products = () => {
  const { adminProductState, adminProductDispatch } =
    useContext<any>(GlobalContext);
  const [products, setProducts] = useState<Admin[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchAdminProducts();
  }, []);

  useEffect(() => {
    if (adminProductState?.adminProducts) {
      const products = adminProductState?.adminProducts;
      setProducts(products);
      adminProductDispatch({ type: GET_ADMIN_PRODUCTS, payload: null });
    }
    if (adminProductState?.adminProductsError) {
      setIsErrorOpen(true);
      setErrorMessage(adminProductState?.adminProductsError);
    }
  }, [adminProductState, adminProductDispatch]);

  const fetchAdminProducts = async () => {
    setLoading(true);
    await getAdminProducts()(adminProductDispatch);
    setLoading(false);
  };

  const getStatusColor = useCallback((status: string) => {
    switch (status) {
      case 'published':
        return '#3EB83D';
      case 'pending':
        return '#F6D35F';
      case 'rejected':
        return '#E53A34';
      case 'draft':
        return 'gray';
      default:
        return 'black';
    }
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    });
  };

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div className={classes.header}>
        <Header title="Products" />
      </div>
      <div className={classes.table}>
        <table>
          <thead>
            <tr>
              <th className={classes.id}> ID</th>
              <th className={classes.name}>
                <div className={classes.drop_down}>
                  <HiChevronDown />
                  <p>Product Name</p>
                </div>
              </th>
              <th className={classes.type}>Category</th>
              <th>Status</th>
              <th className={classes.created}>Created On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              return (
                <tr key={product.id}>
                  <td className={classes.input_sec}>
                    <input type="checkbox" />
                    {product.id}
                  </td>
                  <td className={classes.name}>{product.name}</td>
                  <td className={classes.type}>{product.type}</td>
                  <td
                    style={{
                      fontWeight: 600,
                      textTransform: 'capitalize',
                      color: getStatusColor(product.status),
                    }}
                  >
                    {product.status}
                  </td>
                  <td>{formatDate(product.createdAt)}</td>
                  <td>
                    <Link to={`/admin/dashboard/products/${product.id}`}>
                      <button className={classes.view}>View</button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className={classes.footer}>
              Showing: {products.length} products
            </tr>
          </tfoot>
        </table>
        {loading && (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Products;
