import {
  GET_ADMIN_PRODUCTS,
  GET_ADMIN_PRODUCTS_ERROR,
  GET_ADMIN_PRODUCT,
  GET_ADMIN_PRODUCT_ERROR,
} from '../../actions/types';

export interface adminProductState {}

export const adminProductInitialState: adminProductState = {
  adminProducts: null,
  adminProductsError: null,
  adminProduct: null,
  adminProductError: null,
};

const adminProductReducer = (
  state: adminProductState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ADMIN_PRODUCTS:
      return {
        ...state,
        adminProducts: action.payload,
        adminProductsError: null,
      };

    case GET_ADMIN_PRODUCTS_ERROR:
      return {
        ...state,
        adminProductsError: action.payload,
        adminProducts: null,
      };
    case GET_ADMIN_PRODUCT:
      return {
        ...state,
        adminProduct: action.payload,
        adminProductError: null,
      };

    case GET_ADMIN_PRODUCT_ERROR:
      return {
        ...state,
        adminProductError: action.payload,
        adminProduct: null,
      };
    default:
      return state;
  }
};

export default adminProductReducer;
