import Login from '../pages/auth/login/login';
import SignUp from '../pages/auth/signup';
import ForgotPassword from '../pages/auth/forgotpassword';
import StoreForm from '../pages/auth/storeForm';
import Error from '../pages/error';
import Help from '../pages/seller/help';
import SellerHome from '../pages/seller/home';
import ProductSeller from '../pages/seller/product';
import Settings from '../pages/seller/settings';
import Store from '../pages/seller/store';
import SearchResults from '../pages/user/search';
import Stores from '../pages/user/stores';
import dashboardIcon from '../Assets/dashboard.svg';
import WishList from '../pages/user/wish-list';
import {
  helpOutline,
  listOutline,
  logOutOutline,
  settingsSharp,
  storefrontSharp,
  swapHorizontal
} from 'ionicons/icons';
import ProductDetails from '../pages/user/product-details';
import SingleStore from '../pages/user/single-store';
import VerifyPassword from '../pages/auth/verify-password';
import ResetPassword from '../pages/auth/reset-password';
import PasswordResetSuccess from '../pages/auth/PasswordResetSuccess';
import AboutUs from '../pages/user/AboutUs';
import CategoriesPage from '../pages/user/Categories';
import AdministratorLogin from '../pages/auth/AdminLogin';
import AdminHome from '../pages/Admin/home';
import AdminStores from '../pages/Admin/stores/stores'
import AdminProducts from '../pages/Admin/products/products'
import AdminStoresApprovals from '../pages/Admin/approvals/stores'
import AdminProductsApprovals from '../pages/Admin/approvals/products'
import CategoryProducts from '../pages/user/Product';
import HelpCenter from '../pages/user/Help-Center';
import BulkProducts from '../pages/seller/bulk-products';
import CreateEditProduct from '../pages/seller/createEditProduct';
import AllQuestions from '../pages/user/AllQuestions';
import Landing from '../pages/user/landing';
import CreateAccount from '../pages/auth/create-account';
import RegisterVendor from '../pages/auth/register-vendor';
import Profile from '../pages/user/profile';
import Account from '../pages/user/account';
import AdminStoreDetails from '../pages/Admin/stores/storeDetails';
import ShopProducts from '../pages/seller/products';
import { CurrentLocation } from '../components/storeMenu/menu';
import AdminUsers from '../pages/Admin/users';
import AdminProductDetails from '../pages/Admin/products/productDetails';

export type RouteType = {
  path: string;
  component: React.ComponentType;
  icon?: any;
  name?: string;
  isAux?: boolean;
  isSwitch?: boolean;
  roles?: string[]
};
export const getDashboardRoutes =  ({location}:{location: CurrentLocation}) =>{
  const isOnDashBoard = location?.pathname?.includes('dashboard');
  return [
  {
    path: '/dashboard/home',
    component: SellerHome,
    icon: dashboardIcon,
    name: 'Dashboard',
    roles: ['seller']
  },
  {
    path: '/dashboard/products',
    component: ShopProducts,
    icon: listOutline,
    name: 'Products',
    roles: ['seller']
  },
  {
    path: '/dashboard/store',
    component: Store,
    icon: storefrontSharp,
    name: 'Shop details',
    roles: ['seller']
  },
  { path: '/dashboard/create-store',
   component: StoreForm,
   roles: ['seller']
  },
  // {
  //   path: '/dashboard/help',
  //   component: Help,
  //   icon: helpOutline,
  //   roles: ['seller'],
  //   name: 'Help',
  // },
  {
    path: '/dashboard/items/:item',
    component: ProductSeller,
    roles: ['seller']
  },
  {
    path: '/dashboard/settings',
    component: Settings,
    roles: ['seller'],
    icon: settingsSharp,
    name: 'Settings',
  },
  {
    path: isOnDashBoard ? '/stores': '/dashboard/home',
    component: Stores,
    roles: ['seller'],
    icon: swapHorizontal,
    name: isOnDashBoard ? 'Switch to shopping': 'Switch to dashboard',
  },
  {
    path: '/dashboard/create-product',
    component: CreateEditProduct,
    roles: ['seller']
  },
  {
    path: '/dashboard/edit-product/:item',
    component: CreateEditProduct,
    roles: ['seller']
  },
  {
    path: '/dashboard/bulk-products',
    component: BulkProducts,
    roles: ['seller']
  },

  {
    path: '/login',
    component: Login,
    name: 'Log Out',
    icon: logOutOutline,
    isAux: true,
  },
]
}

const adminRoutes = [
  { path: '/admin/login',
    component: AdministratorLogin,
  },
  {
    path: '/admin/dashboard/home',
    component: AdminHome,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/stores',
    component: AdminStores,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/products',
    component: AdminProducts,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/users',
    component: AdminUsers,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/approvals/stores',
    component: AdminStoresApprovals,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/approvals/products',
    component: AdminProductsApprovals,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/stores/:id',
    component: AdminStoreDetails,
    name: 'Administrator',
    roles: ['admin']
  },
  {
    path: '/admin/dashboard/products/:id',
    component: AdminProductDetails,
    name: 'Administrator',
    roles: ['admin']
  },
]

export const getMainRoutes =  ({location}:{location:CurrentLocation}): RouteType[] => {
  const dashboardRoutes = getDashboardRoutes({location})

  return [
  // { path: '/', component: Home },
  { path: '/', component: Landing },
  { path: '/login', component: Login, name: 'Log Out' },
  { path: '/welcome', component: CreateAccount },
  { path: '/signup', component: SignUp },
  { path: '/registervendor', component: RegisterVendor },
  { path: '/forgotpassword', component: ForgotPassword },
  { path: '/verifypassword', component: VerifyPassword },
  { path: '/resetpassword', component: ResetPassword },
  { path: '/reset-password-success', component: PasswordResetSuccess },
  { path: '/stores', component: Stores },
  { path: '/err', component: Error },
  { path: '/search', component: SearchResults },
  { path: '/wishlist', component: WishList,     roles: ['user','seller','admin']  },
  { path: '/aboutus', component: AboutUs },
  { path: '/categories', component: CategoriesPage },
  { path: '/help', component: HelpCenter },
  { path: '/faqs', component: AllQuestions },
  { path: '/categories/:type', component: CategoryProducts },
  { path: '/profile', component: Profile, roles: ['user','seller','admin'] },
  { path: '/account', component: Account, roles: ['user','seller','admin'] },
  ...adminRoutes,
  ...dashboardRoutes,

  // These should always be the last routes in this array. Put new routes above this -------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------
  { path: '/:store', component: SingleStore },
  { path: '/:store/product/:item', component: ProductDetails },
]};