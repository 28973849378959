import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonInput,
  IonItem,
  IonTextarea,
} from '@ionic/react';
import { checkmark, close } from 'ionicons/icons';
import './styles.scss';
import { TextFieldTypes } from '@ionic/core';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

export interface InputCardTypes {
  Ref?: any;
  onFocusOff?: (event: CustomEvent<FocusEvent>) => void;
  onFocus?: (event: CustomEvent<FocusEvent>) => void;
  onBlur?: (event: CustomEvent<any>) => void;
  type: TextFieldTypes;
  placeholder: string;
  formValues?: any;
  name: string;
  borders?: boolean;
  label?: boolean;
  icon?: boolean;
  disabled?: boolean;
  mode?: 'ios' | 'md';
  maxLength?: number | 256;
  minLength?: number | 1;
  clear?: boolean;
  inputType: 'text-field' | 'text-box';
  required: boolean;
  value: (arg0: (form: any) => any) => any;
  enterEvent?: (event: KeyboardEvent, value: string) => void;
  color?: string;
  background?: string;
}

const InputCard = ({
  Ref,
  onFocusOff,
  onFocus,
  borders,
  onBlur,
  color,
  background,
  type,
  placeholder,
  name,
  icon,
  mode,
  maxLength,
  minLength,
  clear,
  inputType,
  required,
  formValues,
  value,
  enterEvent,
  disabled,
}: InputCardTypes) => {
  const [isNumberInput, setIsNumberInput] = useState<boolean>(false);
  const [_value, setValue] = useState<string>('');
  const optionalProps: { value?: string | number } = {};

  useEffect(() => {
    if (type === 'number') {
      setIsNumberInput(true);
    }
  }, []);
  if (formValues) {
    optionalProps.value = formValues?.[name];
  }
  const dispatchEnterAction = (e: Event) => {
    e.target?.addEventListener('keydown', (e: Event) => {
      if ((e as KeyboardEvent).key === 'Enter' && enterEvent) {
        enterEvent(e as KeyboardEvent, _value);
      }
    });
  };
  const onInputChange = (e: string) => {
    let val = e;
    if (isNumberInput) {
      if (/[^0-9.,]/.test(val)) {
        val = `${val.slice(0, -1)}`;
      }
      const formated = val
        .toString()
        .replace(/,/g, '')
        .replace(/(?<=\d)(?=(\d{3})+(?!\d))/g, ',');
      val = formated;
    }
    setValue(val);
    value((form: any) => {
      if (Object.prototype.toString.call(form) === '[object Object]') {
        return {
          ...form,
          [name]: val,
        };
      }
      if (Array.isArray(form)) {
        return [...form, val];
      }
    });
  };
  const onTextBoxChange = (e: string) => {
    value((form: any) => {
      if (Object.prototype.toString.call(form) === '[object Object]') {
        return {
          ...form,
          [name]: e,
        };
      }
      if (Array.isArray(form)) {
        return [...form, e];
      }
    });
  };
  return (
    <IonCard
      className={'inputCard  size-full overflow-hidden '}
      mode={mode}
      style={{
        border: borders ? '1px solid rgba(15, 50, 77, 0.5)' : 'none',
        borderOpacity: '0.6',
        '--background': background ? background : 'white',
      }}
    >
      <IonCardContent className={'size-full bg-transparent'}>
        <IonItem
          lines="none"
          className={
            'size-full p-0 flex flex-row items-center justify-center relative'
          }
          style={{
            '--background': 'transparent',
            '--color': color ? color : 'var(--ion-color-primary)',
          }}
        >
          {inputType === 'text-box' ? (
            <IonTextarea
              {...(optionalProps as { value: string | null | any })}
              onIonFocus={dispatchEnterAction}
              required={required}
              ref={Ref}
              placeholder={placeholder}
              name={name}
              style={{
                '--background': 'transparent',
                '--color': color ? color : 'var(--ion-color-primary)',
                'caret-color': color ? color : 'var(--ion-color-primary)',
              }}
              onIonBlur={onFocusOff}
              minlength={minLength}
              maxlength={maxLength}
              onIonChange={(e) => onTextBoxChange(e.detail!?.value!)}
            ></IonTextarea>
          ) : (
            <IonInput
              {...optionalProps}
              onIonFocus={dispatchEnterAction}
              required={required}
              ref={Ref}
              type={isNumberInput ? 'text' : type}
              className="!focus:border-b-2 "
              placeholder={placeholder}
              name={name}
              pattern={'string'}
              style={{
                '--background': 'transparent',
                '--color': color ? color : 'var(--ion-color-primary)',
                'caret-color': color ? color : 'var(--ion-color-primary)',
              }}
              clearInput={clear}
              onIonBlur={onBlur}
              minlength={minLength}
              maxlength={maxLength}
              onIonChange={(e) => onInputChange(e.detail!?.value!)}
            ></IonInput>
          )}

          {icon !== undefined &&
            (icon === true ? (
              <IonIcon slot="end" color="success" icon={checkmark}></IonIcon>
            ) : (
              <IonIcon slot="end" color="danger" icon={close}></IonIcon>
            ))}
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default InputCard;
