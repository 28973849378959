import { axiosInstance } from '../../../utils/axios';
import { Dispatch } from 'react';
import {
  GET_ADMIN_PRODUCT,
  GET_ADMIN_PRODUCTS_ERROR,
  GET_ADMIN_PRODUCTS,
  GET_ADMIN_PRODUCT_ERROR,
} from '../types';

export const getAdminProducts =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/admin/products`);
      dispatch({ type: GET_ADMIN_PRODUCTS, payload: res.data.data });
    } catch (error: any) {
      dispatch({
        type: GET_ADMIN_PRODUCTS_ERROR,
        payload: error.response.data.message || error.message,
      });
    }
  };

  export const getAdminProduct =
  (id: string | number) => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/admin/products/${id}`);
      dispatch({ type: GET_ADMIN_PRODUCT, payload: res.data.data });
    } catch (error: any) {
      dispatch({
        type: GET_ADMIN_PRODUCT_ERROR,
        payload: error.response.data.message || error.message,
      });
    }
  };
